import React from "react"
import PropTypes from "prop-types"
import CssBaseline from "@material-ui/core/CssBaseline"
import Container from "@material-ui/core/Container"
import Header from "../components/page/Header"
import { ThemeProvider } from "@material-ui/styles"
import { theme } from "../var/theme"
import NoSsr from "@material-ui/core/NoSsr"
import { Card, CardContent, createStyles, makeStyles, useScrollTrigger } from "@material-ui/core"
import Zoom from "@material-ui/core/Zoom"
import Fab from "@material-ui/core/Fab"
import { KeyboardArrowUpRounded as KeyboardArrowUpIcon } from "@material-ui/icons"
import { scrollTo } from "../var/util"
import Footer from "../components/partials/Footer"
import Typography from "@material-ui/core/Typography"
import Meta from "../components/Meta"
import Link from "@material-ui/core/Link"
import PageTitle from "../components/buttons/PageTitle"

const useStyles = makeStyles((t) => createStyles({
  main: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
  },
  scroll: {
    position: "fixed",
    bottom: t.spacing(2),
    right: t.spacing(2),
  },
  toolbar: {
    ...t.mixins.toolbar,
    background: theme.custom.fancyBackground,
  },
  content: {
    flex: 1,
  },
  card: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },

}))

function ScrollTop(props) {
  const { children, window } = props
  const classes = useStyles()
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = event => {
    scrollTo("#top-anchor")
  }

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.scroll}>
        {children}
      </div>
    </Zoom>
  )
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

// https://app-privacy-policy-generator.nisrulz.com/
const PrivacyPage = () => {
  const classes = useStyles()
  // const prefersDarkMode = 0//useMediaQuery("(prefers-color-scheme: dark)")
  // const appTheme = React.useMemo(
  //   () =>
  //     prefersDarkMode ? darkTheme : theme,
  //   [prefersDarkMode],
  // )
  return (
    <React.Fragment>
      <Meta title={"Privacy Policy"}/>
      <NoSsr>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <main className={classes.main}>
            <Header title="Memo Reminders"/>
            <section id={"content"} className={classes.content}>
              <div className={classes.toolbar}/>
              <Container maxWidth={"md"}>
                <PageTitle title={"Privacy policy"}/>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      This privacy policy has been compiled to better serve those who are concerned with how their
                      ‘Personally Identifiable Information’ (PII) is being used online. PII, as described in US privacy
                      law and information security, is information that can be used on its own or with other information
                      to identify, contact, or locate a single person, or to identify an individual in context. Please
                      read our privacy policy carefully to get a clear understanding of how we collect, use, protect or
                      otherwise handle your Personally Identifiable Information in accordance with our website.
                    </Typography>

                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      Both memoreminders.com ("Website") and the Memo Reminders app are services ("SERVICE")
                      of <b>datanasov.com</b>.
                    </Typography>
                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      This page is used to inform visitors regarding our
                      policies with the collection, use, and disclosure of Personal
                      Information if anyone decided to use our Service.
                    </Typography>

                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      If you choose to use our Service, then you agree to
                      the collection and use of information in relation to this
                      policy. The Personal Information that we collect is
                      used for providing and improving the Service. we will not use or share your information with
                      anyone except as described in this Privacy Policy.
                    </Typography>

                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      The terms used in this Privacy Policy have the same meanings
                      as in our Terms and Conditions, which is accessible at
                      Memo Reminders unless otherwise defined in this Privacy Policy.
                    </Typography>

                    <Typography align={"center"} variant={"h6"}>
                      Information Collection and Use
                    </Typography>

                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      For a better experience, while using our Service, we
                      may require you to provide us with certain personally
                      identifiable information. The information that
                      we request will be retained by us and used as described in this privacy policy.
                    </Typography>
                    <div>
                      <Typography align={"left"} variant={"body1"} gutterBottom>
                        The app does use third party services that may collect
                        information used to identify you.
                      </Typography>

                      <Typography align={"left"} variant={"body1"} gutterBottom>
                        Link to privacy policy of third party service providers used
                        by the app
                      </Typography>
                      <ul>
                        <li>
                          <Link target="_blank" rel="noopener" href="https://www.google.com/policies/privacy/"
                          >Google Play Services</Link>
                        </li>
                        <li>
                          <Link target="_blank" rel="noopener" href="https://firebase.google.com/policies/analytics"
                          >Google Analytics for Firebase</Link></li>
                      </ul>
                    </div>
                    <Typography align={"center"} variant={"h6"}>Log Data</Typography>
                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      we want to inform you that whenever you
                      use our Service, in a case of an error in the app
                      we collect data and information (through third party
                      products) on your phone called Log Data. This Log Data may
                      include information such as your device Internet Protocol
                      ("IP") address, device name, operating system version, the
                      configuration of the app when utilizing our Service,
                      the time and date of your use of the Service, and other
                      statistics.
                    </Typography>

                    <Typography align={"center"} variant={"h6"}>Cookies</Typography>

                    <Typography
                      align={"left"} variant={"body1"} gutterBottom>
                      Cookies are files with a small amount of data that are
                      commonly used as anonymous unique identifiers. These are sent
                      to your browser from the websites that you visit and are
                      stored on your device's internal memory.
                    </Typography>

                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      This Service does not use these "cookies" explicitly. However,
                      the app may use third party code and libraries that use
                      "cookies" to collect information and improve their services.
                      You have the option to either accept or refuse these cookies
                      and know when a cookie is being sent to your device. If you
                      choose to refuse our cookies, you may not be able to use some
                      portions of this Service.
                    </Typography>

                    <Typography align={"center"} variant={"h6"}>Service
                      Providers</Typography>

                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      we may employ third-party companies and
                      individuals due to the following reasons:
                    </Typography>
                    <ul>
                      <li>To facilitate our Service;</li>
                      <li>To provide the Service on our behalf;</li>
                      <li>To perform Service-related services; or</li>
                      <li>To assist us in analyzing how our Service is used.</li>
                    </ul>
                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      we want to inform users of this Service
                      that these third parties have access to your Personal
                      Information. The reason is to perform the tasks assigned to
                      them on our behalf. However, they are obligated not to
                      disclose or use the information for any other purpose.
                    </Typography>

                    <Typography align={"center"} variant={"h6"}>Security</Typography>

                    <Typography
                      align={"left"} variant={"body1"} gutterBottom>
                      we value your trust in providing us your
                      Personal Information, thus we are striving to use commercially
                      acceptable means of protecting it. But remember that no method
                      of transmission over the internet, or method of electronic
                      storage is 100% secure and reliable, and we cannot
                      guarantee its absolute security.
                    </Typography>

                    <Typography align={"center"} variant={"h6"}>Links to Other
                      Sites</Typography>

                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      This Service may contain links to other sites. If you click on
                      a third-party link, you will be directed to that site. Note
                      that these external sites are not operated by us.
                      Therefore, we strongly advise you to review the
                      Privacy Policy of these websites. we have
                      no control over and assume no responsibility for the content,
                      privacy policies, or practices of any third-party sites or
                      services.
                    </Typography>

                    <Typography align={"center"} variant={"h6"}>Children’s Privacy</Typography>

                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      These Services do not address anyone under the age of 13.
                      we do not knowingly collect personally
                      identifiable information from children under 13. In the case
                      we discover that a child under 13 has provided
                      us with personal information, we immediately
                      delete this from our servers. If you are a parent or guardian
                      and you are aware that your child has provided us with
                      personal information, please contact us so that
                      we will be able to do necessary actions.
                    </Typography>

                    <Typography align={"center"} variant={"h6"}>Changes to This Privacy Policy</Typography>

                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      We may update our Privacy Policy from
                      time to time. Thus, you are advised to review this page
                      periodically for any changes. we will
                      notify you of any changes by posting the new Privacy Policy on
                      this page.
                    </Typography>

                    <Typography align={"left"} variant={"body1"} gutterBottom>This policy is effective as of
                      2020-06-01</Typography>
                    <Typography align={"left"} variant={"body1"} gutterBottom><strong>Contact Us</strong></Typography>
                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      If you have any questions or suggestions about our
                      Privacy Policy, do not hesitate to contact us at <Link
                      href={"mailto:hello@memoreminders.com"}>hello@memoreminders.com</Link>.
                    </Typography>
                  </CardContent>
                </Card>
              </Container>
            </section>
            <Footer excludeSupport={true}/>
          </main>
          <ScrollTop>
            <Fab color="secondary" size="small" aria-label="scroll back to top">
              <KeyboardArrowUpIcon/>
            </Fab>
          </ScrollTop>
        </ThemeProvider>
      </NoSsr>
    </React.Fragment>
  )
}

export default PrivacyPage
