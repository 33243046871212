import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { createStyles } from "@material-ui/core"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import { theme } from "../../var/theme"


const useStyles = makeStyles((t) => createStyles({
  titleCard: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(3),
  },
}))

const PageTitle = ({ title }) => {
  const classes = useStyles()
  return (
    <Card className={classes.titleCard}>
      <CardContent>
        <Typography align={"center"} variant={"h6"}>{title}</Typography>
      </CardContent>
    </Card>
  )
}

export default PageTitle
